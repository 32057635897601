import React from "react"
import Layout from "../components/layout"
import styled from 'styled-components'
import { graphql } from "gatsby"
import parse from 'html-react-parser'

const Wrapper = styled.div`
    width: 100vw;
    min-height: 100vh;
    padding: 0vh 20vw 20vh 20vw;
    display: flex;
    flex-direction: column;
    align-items: center;
`

const VideoWrapper = styled.div`
    width: 100%;
    padding-top: 56.25%;
    position: relative;
`

const TextWrapper = styled.p`
    width: 100%;
    text-align: left;
`
const Space = styled.div`
    height: 10vh
`

const Image = styled.img`
    width: 100%;
    height: auto
`

const About = props => {

    return ( 
        <Layout
            title = { 'About' }
            index
            description = { '25+ years Trusted Business Partner for Quality, Price and Service' }
        >
            <Wrapper>
                <div style = {{ color: 'white' }}>About Siu Sons, 25+ years Trusted Business Partner for Quality, Price and Service</div>
                <VideoWrapper>
                    <iframe 
                        src = { props.data.allDatoCmsAbout.edges[0].node.aboutVideo } 
                        frameborder="0" 
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                        allowfullscreen
                        title = { 'About us' }
                        style = {{
                            width: '100%',
                            height: '100%',
                            margin: 0,
                            position: 'absolute',
                            top:0,
                            left:0,
                            right:0,
                            bottom:0
                        }}
                    />
                </VideoWrapper>
                <Space/>
                <TextWrapper>
                    <h1 style = {{ color: '#283593' }} data-sal="slide-up">About us</h1>
                    {
                        parse( props.data.allDatoCmsAbout.edges[0].node.aboutPart1 )
                    }
                </TextWrapper>
                <Space/>
                <TextWrapper>               
                    <h1 style = {{ color: '#283593' }} data-sal="slide-up">Connecting you to the World of Partying Supplies</h1>
                </TextWrapper>
                <Space/>
                <Image 
                    src = { 
                        props.data.allDatoCmsAbout.edges[0].node.aboutUsImage.url ? 
                        props.data.allDatoCmsAbout.edges[0].node.aboutUsImage.url 
                        : 
                        null 
                    } 
                />
                <Space/>
                <TextWrapper>
                    <h1 style = {{ color: '#283593' }} data-sal="slide-up">Our mission</h1>
                    {
                        parse( props.data.allDatoCmsAbout.edges[0].node.aboutPart2 )
                    }
                </TextWrapper>
            </Wrapper>  
        </Layout>
    )
}
export default About

export const query = graphql`
    query About {
        allDatoCmsAbout {
            edges {
                node {
                aboutPart1
                aboutPart2
                aboutUsImage {
                    url
                }
                aboutVideo
                }
            }
        }
    }
`